import { toastError } from "@3edges/utils/dist/toastify";
import { isNotEmpty } from "@3edges/utils/dist/utils";
import { useLazyQuery, useMutation } from "@apollo/client";
import { MenuItem } from "@material-ui/core";
import ModalEditor from "components/ModalEditor";
import { useDeleteD3Items } from "components/PrimGraphicalCanvas/deleteD3Items/useDeleteD3Items";
import { EDeleteType, EUpdateType, NodeTypename } from "components/PrimGraphicalCanvas/types";
import { useUpdateD3Items } from "components/PrimGraphicalCanvas/updateD3Items";
import { useData } from "contexts/dataContext";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    NiamFieldTypeEnum,
    rPCustomMutationGet as RPCustomMutationGet,
    rPUpdateCustomMutation as RPUpdateCustomMutation
} from "types/operation-result-types";
import { TextField } from "ui-components/styleds";
import { RP_DELETE_CUSTOM_MUTATION, RP_GET_CUSTOM_MUTATION, RP_UPDATE_CUSTOM_MUTATION } from "./gql";
import { Divider, Form, LoadingIconStyled, RightPanelDeleteIconStyled, RightPanelLoadingStyled, SelectFieldStyled } from "./styled";

interface RPCustomMutationProps {
    isOpen: boolean;
    setIsOpen: (state: boolean) => void;
}

function RPCustomMutation({ isOpen, setIsOpen }: RPCustomMutationProps): React.ReactElement {
    const { server, setItemSelected, itemSelected } = useData();
    const onDelete = useDeleteD3Items();
    const onUpdate = useUpdateD3Items();

    const [getData, { data, loading }] = useLazyQuery<RPCustomMutationGet>(RP_GET_CUSTOM_MUTATION, { fetchPolicy: "no-cache" });

    const [name, setName] = useState<string>(undefined);
    const [displayName, setDisplayName] = useState<string>(undefined);
    const [mutatuinName, setMutatuinName] = useState<string>(undefined);
    const [returnType, setReturnType] = useState<string>(undefined);
    const [script, setScript] = useState<string>(undefined);
    const [args, setArgs] = useState<string>(undefined);
    const [inputSchema, setInputSchema] = useState<string>(undefined);

    const { t } = useTranslation();
    const [updateCustomMutation, { loading: updLoading }] = useMutation<RPUpdateCustomMutation>(RP_UPDATE_CUSTOM_MUTATION);
    const [deleteCustomMutation] = useMutation(RP_DELETE_CUSTOM_MUTATION);

    const isComponentUnMounted = useRef(false);

    useEffect(() => {
        // standard procedure for processing componentDidUnmount action
        // here, we set isComponentUnMounted.current flag to true
        return () => {
            isComponentUnMounted.current = true;
        }
    }, []);

    const turnToUnsaved = (): void => {
        setItemSelected({ ...itemSelected, hasSaved: false })
    };

    useEffect(() => {
        if (isNotEmpty(itemSelected.data._id)) {
            getData({ variables: { customMutationID: itemSelected.data._id } });
        }
    }, [itemSelected.data]);

    useEffect(() => {
        if (data && !loading) {
            setName(data.niamCustomMutation.name);
            setDisplayName(data.niamCustomMutation.displayName);
            setArgs(data.niamCustomMutation.args as string);
            setInputSchema(data.niamCustomMutation.inputSchema);
            setMutatuinName(data.niamCustomMutation.mutatuinName);
            setReturnType(data.niamCustomMutation.returnType);

            if (isNotEmpty(data.niamCustomMutation.script)) {
                setScript(atob(data.niamCustomMutation.script));
            } else {
                setScript("");
            }
        }
    }, [loading, data]);

    const handleOnBlurEvent = (event: any): void => {
        if (isOpen && !itemSelected.hasSaved) {
            updateCustomMutation({
                variables: {
                    serverID: server._id,
                    customMutationID: data.niamCustomMutation._id,
                    params: {
                        displayName,
                        name,
                        mutatuinName,
                        returnType,
                        args,
                        inputSchema,
                        script: btoa(script)
                    }
                }
            }).then(({ errors, data: dataUpdated }: any) => {
                if (errors) {
                    for (const e of errors) {
                        toastError(t(`validations:${e.message}`));
                    }

                    return;
                }

                onUpdate({
                    id: data.niamCustomMutation._id,
                    type: EUpdateType.CUSTOM_MUTATION,
                    newData: dataUpdated.updateNiamCustomMutation,
                    rPData: itemSelected.data
                });

                if (isComponentUnMounted.current === false) {
                    setName(dataUpdated.updateNiamCustomMutation.name);
                    setArgs(dataUpdated.updateNiamCustomMutation.args as string);
                    setInputSchema(dataUpdated.updateNiamCustomMutation.inputSchema);
                    setDisplayName(dataUpdated.updateNiamCustomMutation.displayName);
                    setMutatuinName(dataUpdated.updateNiamCustomMutation.mutatuinName);
                    setReturnType(dataUpdated.updateNiamCustomMutation.returnType);

                    if (isNotEmpty(dataUpdated.updateNiamCustomMutation.script)) {
                        setScript(atob(dataUpdated.updateNiamCustomMutation.script));
                    } else {
                        setScript("");
                    }

                    itemSelected.data.displayName = dataUpdated.updateNiamCustomMutation.displayName
                    setItemSelected({ ...itemSelected, hasSaved: true })
                }
            });
        }
    }

    return (
        <>
            <RightPanelLoadingStyled $isShowing={loading || updLoading}>
                <LoadingIconStyled />
            </RightPanelLoadingStyled>

            <Form>
                <div data-cy="cypressHiddenDiv" />

                <TextField
                    data-cy="rpCMPage_name"
                    label={t("rightPanel:customMutation.input.name.label")}
                    onChange={(e) => {
                        setName(e.target.value);
                        setDisplayName(e.target.value);
                        turnToUnsaved();
                    }}
                    onBlur={(e) => {
                        handleOnBlurEvent(e);
                    }}
                    value={name || ""}
                />

                <TextField
                    data-cy="rpCMPage_mutatuinName"
                    label={t("rightPanel:customMutation.input.mutatuinName.label")}
                    onChange={(e) => {
                        setMutatuinName(e.target.value);
                        turnToUnsaved();
                    }}
                    onBlur={(e) => {
                        handleOnBlurEvent(e);
                    }}
                    value={mutatuinName || ""}
                />

                <TextField
                    $shouldBeLarge
                    label={t("rightPanel:customMutation.input.args.label")}
                    onChange={(e) => {
                        setArgs(e.target.value);
                        turnToUnsaved();
                    }}
                    onBlur={(e) => {
                        handleOnBlurEvent(e);
                    }}
                    value={args || ""}
                />

                <SelectFieldStyled
                    value={returnType || "String"}
                    label={t("rightPanel:customMutation.input.returnType.label")}
                    onChange={(event) => {
                        setReturnType(event.target.value);
                        turnToUnsaved();
                    }}
                    onBlur={(e) => {
                        handleOnBlurEvent(e);
                    }}
                >
                    {Object.keys(NiamFieldTypeEnum)
                        .filter((ft) => ft !== "Relationship" && ft !== NodeTypename.NIAM_ENUM && ft !== "Object")
                        .map((niamFieldType) => (
                            <MenuItem key={`niamFieldType-${niamFieldType}`} value={niamFieldType}>
                                {niamFieldType}
                            </MenuItem>
                        ))}
                </SelectFieldStyled>

                <TextField
                    $shouldBeLarge
                    label={t("rightPanel:customMutation.input.inputSchema.label")}
                    onChange={(e) => {
                        setInputSchema(e.target.value);
                        turnToUnsaved();
                    }}
                    onBlur={(e) => {
                        handleOnBlurEvent(e);
                    }}
                    value={inputSchema || ""}
                />

                <ModalEditor
                    scriptText={script || ""}
                    onChangeScript={(vlr) => {
                        setScript(vlr);
                        turnToUnsaved();
                    }}
                    onBlurScript={(e) => {
                        handleOnBlurEvent(e);
                    }}
                />

                <Divider />

                <RightPanelDeleteIconStyled
                    onClick={() => {
                        setIsOpen(false);

                        deleteCustomMutation({
                            variables: {
                                customMutationID: data.niamCustomMutation._id
                            }
                        }).then(({ errors }: any) => {
                            if (errors) {
                                for (const e of errors) {
                                    toastError(t(`validations:${e.message}`));
                                }
                                return;
                            }

                            onDelete({
                                id: data.niamCustomMutation._id,
                                type: EDeleteType.CUSTOM_MUTATION
                            });
                        });
                    }}
                />
            </Form>
        </>
    );
}

export default RPCustomMutation;
