/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { toastError, toastWarning } from "@3edges/utils/dist/toastify";
import { isNotEmpty } from "@3edges/utils/dist/utils";
import { useLazyQuery, useMutation } from "@apollo/client";
import { MenuItem } from "@material-ui/core";
import { ButtonListFlex, ConfirmButton } from "components/ModalDataServer/styled";
import { deleteD3Field } from "components/PrimGraphicalCanvas/deleteD3Items/field";
import { useDeleteD3Items } from "components/PrimGraphicalCanvas/deleteD3Items/useDeleteD3Items";
import { EDeleteType, EUpdateType, NodeTypename } from "components/PrimGraphicalCanvas/types";
import { useUpdateD3Items } from "components/PrimGraphicalCanvas/updateD3Items";
import { useCanvasContext } from "contexts/canvasContext";
import { useData } from "contexts/dataContext";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { RPPropertyData } from "typedefs";
import
    {
        NiamFieldTypeEnum,
        rPPropertyGet as RPPropertyGet,
        rPUpdateNiamField as RPUpdateNiamField
    } from "types/operation-result-types";
import { TextField } from "ui-components/styleds";
import { RP_DELETE_FIELD, RP_GET_PROPERTY, RP_UPDATE_FIELD } from "./gql";
import
    {
        Divider,
        Form,
        LoadingIconStyled,
        RightPanelBreadCrumbStyled,
        RightPanelDeleteIconStyled,
        RightPanelLoadingStyled,
        SelectFieldStyled,
        SwitchBoxStyled,
        SwitchButton,
        SwitchLabelStyled
    } from "./styled";

interface RPPropertyProps {
    isOpen: boolean;
    setIsOpen: (state: boolean) => void;
}

function RPProperty ({ isOpen, setIsOpen }: RPPropertyProps): React.ReactElement {
    const { d3Data, data: dataContext, setData } = useCanvasContext();
    const { server, setItemSelected, itemSelected } = useData();
    const onDelete = useDeleteD3Items();
    const onUpdate = useUpdateD3Items();
    const [getData, { data, loading }] = useLazyQuery<RPPropertyGet>(RP_GET_PROPERTY, { fetchPolicy: "no-cache" });

    const { t } = useTranslation();

    const [needsToSave, setNeedsToSave] = useState(false);

    const [name, setName] = useState<string>(undefined);
    const [displayName, setDisplayName] = useState<string>(undefined);
    const [fieldType, setFieldType] = useState<string>(undefined);
    const [isRequired, setIsRequired] = useState<boolean>(undefined);
    const [isNaming, setIsNaming] = useState<boolean>(undefined);
    const [isSecret, setIsSecret] = useState<boolean>(undefined);
    const [isArray, setIsArray] = useState<boolean>(undefined);
    const [isUnique, setIsUnique] = useState<boolean>(undefined);
    const [isDisplay, setIsDisplay] = useState<boolean>(undefined);
    const [regex, setRegex] = useState<string>(undefined);
    const [connectedEnum, setConnectedEnum] = useState<unknown>(undefined);
    const [minLength, setMinLength] = useState<number>(undefined);
    const [maxLength, setMaxLength] = useState<number>(undefined);
    const [selectedEnum, setSelectedEnum] = useState<string>("");

    /*
    Currently dataContext.Objects fails to capture a new enum field when it is being connected by drawing a link between the enum and the object.
    But the enum is available inside the dataContext.enums
    Therefore, we are searching for the current enum field in dataContext.objects, dataContext.interfaces, dataContext.nodeRelationships and in dataContext.enums;
    and after finding it we are assigning its identifier to currentFieldEnum
    */
    const allFields = [...dataContext.objects, ...dataContext.interfaces, ...dataContext.nodeRelationships].flatMap((o) => o.fields);
    const enumFields = [...dataContext.enums];
    const currentFieldEnum = allFields?.find((f) => f._id === data?.niamField?._id)?.relatedTo || enumFields.find((f) => f._id === data?.niamField?.relatedTo)?._id;

    const [deleteField] = useMutation(RP_DELETE_FIELD);
    const [updateNiamField, { loading: updLoading }] = useMutation<RPUpdateNiamField>(RP_UPDATE_FIELD);

    const isComponentUnMounted = useRef(false);

    useEffect(() => {
        // standard procedure for processing componentDidUnmount action
        // here, we set isComponentUnMounted.current flag to true
        return () => {
            isComponentUnMounted.current = true;
        }
    }, []);

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (data?.niamField?.fieldType === NodeTypename.NIAM_ENUM) {
            setConnectedEnum(currentFieldEnum);
            setSelectedEnum(currentFieldEnum);
        }

    }, [currentFieldEnum]);

    const turnToUnsaved = (): void => {
        setItemSelected({ ...itemSelected, hasSaved: false })
    };

    useEffect(() => {
        if (needsToSave) {
            setNeedsToSave(false)
            handleOnBlurEvent();
        }
    }, [needsToSave, isSecret, isNaming, isArray, isUnique, isDisplay]);

    useEffect(() => {
        if (isNotEmpty(itemSelected.data._id)) {
            getData({ variables: { FieldID: itemSelected.data._id } });
        }
    }, [itemSelected.data]);

    useEffect(() => {
        if (data && !loading) {
            setName(data.niamField.name);
            setDisplayName(data.niamField.displayName);
            setFieldType(data.niamField.fieldType);
            setIsRequired(data.niamField.isRequired);
            setIsArray(data.niamField.isArray);
            setIsUnique(data.niamField.isUnique);
            setIsDisplay(data.niamField.isDisplay);
            setIsNaming(data.niamField.isNaming);
            setIsSecret(data.niamField.isSecret);
            setRegex(data.niamField.regex);
            setMinLength(data.niamField.minLength);
            setMaxLength(data.niamField.maxLength);
        }
    }, [loading, data]);

    const handleOnBlurEvent = (event?: any): void => {
        const shouldTriggerSave = (isOpen && !itemSelected.hasSaved && fieldType !== "NiamEnum") || (isOpen && !itemSelected.hasSaved && fieldType === "NiamEnum" && connectedEnum);

        if (shouldTriggerSave) {
            let fieldsAlreadyExists = false;
            const parentID = itemSelected.data["parent"]._id;
            const typename = itemSelected.data["parent"].__typename;

            if (typename === NodeTypename.NIAM_INTERFACE) {
                const objectsConnected = []

                dataContext.objects.map(obj => {
                    if (obj.interfaces.length > 0) {
                        const objI = obj.interfaces.find(oi => oi._id === parentID)

                        if (objI) {
                            objectsConnected.push(obj)
                        }
                    }
                })

                objectsConnected.map(obj => {
                    const alreadyExists = obj.fields.find(f => f.name === name && f._id !== data.niamField._id)

                    if (alreadyExists) {
                        fieldsAlreadyExists = true
                    }

                    const objInterfaces = dataContext.interfaces.filter(item => {
                        return obj.interfaces.find(oi => oi._id === item._id)
                    })

                    objInterfaces.map(item => {
                        const alreadyExists = item.fields.find(f => f.name === name && f._id !== data.niamField._id)

                        if (alreadyExists) {
                            fieldsAlreadyExists = true
                        }
                    })
                })
            }

            if (typename === NodeTypename.NIAM_OBJECT) {
                const objParent = dataContext.objects.find(item => item._id === parentID)

                const objInterfaces = dataContext.interfaces.filter(item => {
                    return objParent.interfaces.find(oi => oi._id === item._id)
                })

                objInterfaces.map(item => {
                    const alreadyExists = item.fields.find(f => f.name === name && f._id !== data.niamField._id)

                    if (alreadyExists) {
                        fieldsAlreadyExists = true
                    }
                })
            }

            if (fieldsAlreadyExists) {
                toastWarning(t(`validations:object.fields.already.exists`));
                return
            }

            if (minLength && maxLength) {
                if (minLength >= maxLength) {
                    toastWarning(t(`validations:fields.minLength.more.than.maxLength`));
                    return
                }

                if (maxLength <= minLength) {
                    toastWarning(t(`validations:fields.maxLength.less.than.minLength`));
                    return
                }
            }

            if (isSecret && isNaming) {
                toastError(t(`validations:fields.isSecret.isNaming`));
                return
            }

            const relatedTo = fieldType === "NiamEnum" ? connectedEnum : (itemSelected.data["relatedTo"]?._id || itemSelected.data["relatedTo"]) || itemSelected.data["parent"]?._id;

            updateNiamField({
                variables: {
                    parentType: itemSelected.data.type === "Property" && itemSelected.data.parent.__typename,
                    serverID: server._id,
                    fieldID: itemSelected.data._id,
                    parentID: (itemSelected.data as RPPropertyData).parent._id,
                    params: {
                        displayName,
                        name,
                        fieldType,
                        isRequired,
                        isNaming,
                        isSecret,
                        isArray,
                        isUnique,
                        isDisplay,
                        regex,
                        relatedTo,
                        minLength: Number(minLength),
                        maxLength: Number(maxLength)
                    }
                }
            }).then(({ errors, data: dataUpdated }: any) => {
                if (errors) {
                    for (const e of errors) {
                        toastError(t(`validations:${e.message}`));
                    }
                    return;
                }

                // No need to reset useState variables when this component has unmounted, namely when isComponentUnMounted.current flag is true
                if (isComponentUnMounted.current === false) {
                    setName(dataUpdated.updateNiamField.name);
                    setDisplayName(dataUpdated.updateNiamField.displayName);
                    setFieldType(dataUpdated.updateNiamField.fieldType);
                    setIsRequired(dataUpdated.updateNiamField.isRequired);
                    setIsNaming(dataUpdated.updateNiamField.isNaming);
                    setIsSecret(dataUpdated.updateNiamField.isSecret);
                    setIsArray(dataUpdated.updateNiamField.isArray);
                    setIsUnique(dataUpdated.updateNiamField.isUnique);
                    setIsDisplay(dataUpdated.updateNiamField.isDisplay);
                    setRegex(dataUpdated.updateNiamField.regex);
                    setMinLength(dataUpdated.updateNiamField.minLength);
                    setMaxLength(dataUpdated.updateNiamField.maxLength);

                    if (dataUpdated.updateNiamField.fieldType === "NiamEnum") {
                        setConnectedEnum(dataUpdated.updateNiamField.relatedTo);
                        setSelectedEnum(dataUpdated.updateNiamField.relatedTo);
                    }

                    setItemSelected({ ...itemSelected, hasSaved: true })
                }

                onUpdate({
                    id: data.niamField._id,
                    type: EUpdateType.RELATIONSHIP,
                    newData: dataUpdated.updateNiamField,
                    rPData: itemSelected.data
                });
            });
        }
    }

    return (
        <>
            <RightPanelLoadingStyled $isShowing={loading || updLoading}>
                <LoadingIconStyled />
            </RightPanelLoadingStyled>

            <RightPanelBreadCrumbStyled
                data-cy="rpPropertyPage_btnBreadCrumb"
                $isShowing={isOpen}
                onClick={(event) => {
                    if (data.niamField.fieldType === "Relationship") {
                        d3Data.zoomOnNodesInstance(event, itemSelected.data.type === "Property" && itemSelected.data.parent);
                    } else {
                        if (itemSelected.data.type === "Property") {
                            setItemSelected({ data: itemSelected.data.parent });
                        }
                    }
                }}
            >
                {itemSelected.data.type === "Property" && itemSelected.data.parent.displayName}
            </RightPanelBreadCrumbStyled>

            <Form>
                <div data-cy="cypressHiddenDiv" />

                <TextField
                    data-cy="rpPropertyPage_name"
                    $shouldBeLarge
                    label={t("rightPanel:property.input.name.label")}
                    value={displayName || ""}
                    onChange={(e) => {
                        setDisplayName(e.target.value);
                        setName(e.target.value);
                        turnToUnsaved();
                    }}
                    onBlur={(e) => {
                        handleOnBlurEvent(e);
                    }}
                />

                {NiamFieldTypeEnum.Relationship !== fieldType && (
                    <SelectFieldStyled
                        value={fieldType || "String"}
                        label={t("rightPanel:property.input.fieldType.label")}
                        onChange={(event) => {

                            if (event.target.value !== NiamFieldTypeEnum.String) {
                                setRegex(undefined);
                                setMinLength(undefined);
                                setMaxLength(undefined);
                            }

                            setFieldType(event.target.value);
                            turnToUnsaved();
                        }}
                        onBlur={(e) => {
                            handleOnBlurEvent(e);
                        }}
                        disabled={(itemSelected.data as RPPropertyData).parent.type === "Enum" || fieldType === NodeTypename.NIAM_ENUM}>
                            {Object.keys(NiamFieldTypeEnum)
                            .filter((i) => i !== "Relationship" && i !== "Enum" && i !== "NodeRelationship" && i !== "Object")
                            .map((niamFieldType) => (
                                <MenuItem key={`niamFieldType-${niamFieldType}`} value={niamFieldType}>
                                    {niamFieldType === NodeTypename.NIAM_ENUM ? "Enum" : niamFieldType}
                                </MenuItem>
                            ))}
                    </SelectFieldStyled>
                )}

                <>
                    {[NiamFieldTypeEnum.String].includes(fieldType as NiamFieldTypeEnum) && (
                        <SwitchBoxStyled>
                            <SwitchLabelStyled>
                                {t("rightPanel:property.input.isSecret.label")}
                            </SwitchLabelStyled>

                            <SwitchButton
                                data-cy="rpPropertyPage_isSecret"
                                checked={isSecret || false}
                                onChange={(event) => {
                                    setIsSecret(event.target.checked);
                                    turnToUnsaved();
                                    setNeedsToSave(true);
                                }}
                            />
                        </SwitchBoxStyled>
                    )}

                    {(itemSelected.data as RPPropertyData).parent?._id !== (itemSelected.data as RPPropertyData).relatedTo?._id && (
                        <>
                            {itemSelected.data.type === "Property" &&
                            ![NiamFieldTypeEnum.Relationship, NiamFieldTypeEnum.NiamEnum].includes(fieldType as NiamFieldTypeEnum) &&
                            (itemSelected.data.parent.__typename === NodeTypename.NIAM_OBJECT || itemSelected.data.parent.__typename === NodeTypename.NIAM_INTERFACE)
                            && (
                                <SwitchBoxStyled>
                                    <SwitchLabelStyled>
                                        {t("rightPanel:property.input.isNaming.label")}
                                    </SwitchLabelStyled>

                                    <SwitchButton
                                        data-cy="rpPropertyPage_isNaming"
                                        checked={isNaming || false}
                                        onChange={(event) => {
                                            setIsNaming(event.target.checked);
                                            turnToUnsaved();
                                            setNeedsToSave(true);
                                        }}
                                    />
                                </SwitchBoxStyled>
                            )}
                        </>
                    )}

                    {NiamFieldTypeEnum.Relationship !== fieldType && (
                        <SwitchBoxStyled>
                            <SwitchLabelStyled>
                                {t("rightPanel:property.input.isArray.label")}
                            </SwitchLabelStyled>

                            <SwitchButton
                                checked={isArray || false}
                                onChange={(event) => {
                                    setIsArray(event.target.checked);
                                    turnToUnsaved();
                                    setNeedsToSave(true);
                                }}
                            />
                        </SwitchBoxStyled>
                    )}

                    {[NiamFieldTypeEnum.String, NiamFieldTypeEnum.Int, NiamFieldTypeEnum.Float].includes(fieldType as NiamFieldTypeEnum) && (
                        <SwitchBoxStyled>
                            <SwitchLabelStyled>
                                {t("rightPanel:property.input.isUnique.label")}
                            </SwitchLabelStyled>

                            <SwitchButton
                                checked={isUnique || false}
                                onChange={(event) => {
                                    setIsUnique(event.target.checked);
                                    turnToUnsaved();
                                    setNeedsToSave(true);
                                }}
                            />
                        </SwitchBoxStyled>
                    )}

                    {
                    // You are not able to set "Display" for Relationships and NodeRelationships
                    !(itemSelected.data as RPPropertyData).relatedTo &&
                    (itemSelected.data as RPPropertyData).parent.type !== "NodeRelationship" && (
                        <SwitchBoxStyled>
                            <SwitchLabelStyled>
                                {t("rightPanel:property.input.isDisplay.label")}
                            </SwitchLabelStyled>

                            <SwitchButton
                                checked={isDisplay || false}
                                onChange={(event) => {
                                    setIsDisplay(event.target.checked);
                                    turnToUnsaved();
                                    setNeedsToSave(true);
                                }}
                            />
                        </SwitchBoxStyled>
                    )}
                </>

                {[NiamFieldTypeEnum.NiamEnum].includes(fieldType as NiamFieldTypeEnum) &&
                (itemSelected.data as RPPropertyData).parent.type !== "Enum" &&  (itemSelected.data as RPPropertyData).parent.type !== NodeTypename.NIAM_ENUM && (
                <>
                    <SelectFieldStyled
                        label={t("leftPanel:enum")}
                        value={selectedEnum}
                        onChange={({ target }) => {
                            setConnectedEnum(target.value);
                            setSelectedEnum(target.value);
                            turnToUnsaved();
                        }
                    }>
                        {dataContext.enums.map((en) => (
                            <MenuItem key={`niamEnum-${en._id}`} value={en._id}>
                                {en.name}
                            </MenuItem>
                        ))}
                    </SelectFieldStyled>
                </>
                )}

                {fieldType === NiamFieldTypeEnum.String && (
                    <>
                        <Divider />

                        <TextField
                            label={t("rightPanel:property.input.regex.label")}
                            value={regex || ""}
                            onChange={(e) => {
                                setRegex(e.target.value);
                                turnToUnsaved();
                            }}
                            onBlur={(e) => {
                                handleOnBlurEvent(e);
                            }}
                        />

                        <TextField
                            label={t("rightPanel:property.input.minLength.label")}
                            inputProps={{ type: 'number', min: 0 }}
                            value={minLength || ""}
                            onChange={(e) => {
                                turnToUnsaved();
                                setMinLength(Number(e.target.value));
                            }}
                            onBlur={(e) => {
                                handleOnBlurEvent(e);
                            }}
                        />

                        <TextField
                            label={t("rightPanel:property.input.maxLength.label")}
                            inputProps={{ type: 'number', min: 0 }}
                            value={maxLength || ""}
                            onChange={(e) => {
                                turnToUnsaved();
                                setMaxLength(Number(e.target.value));
                            }}
                            onBlur={(e) => {
                                handleOnBlurEvent(e);
                            }}
                        />
                    </>
                )}

                <ButtonListFlex $justifyContent="space-between">
                    <ConfirmButton
                        onClick={(event) => {
                            if (data.niamField.fieldType === "Relationship") {
                                d3Data.zoomOnNodesInstance(event, itemSelected.data.type === "Property" && itemSelected.data.parent);
                            } else {
                                if (itemSelected.data.type === "Property") {
                                    if (itemSelected.hasSaved || itemSelected.hasSaved === undefined) {
                                        setItemSelected({ data: itemSelected.data.parent });
                                    } else {
                                        const resetContextDataDelay = 500;
                                        setTimeout(() => {
                                            setItemSelected({ data: itemSelected.data.parent });
                                        }, resetContextDataDelay);
                                    }
                                }
                            }
                        }}
                    >
                        Back
                    </ConfirmButton>

                    {[NiamFieldTypeEnum.NiamEnum].includes(fieldType as NiamFieldTypeEnum) && (itemSelected.data as RPPropertyData).parent.type !== "Enum" &&  (itemSelected.data as RPPropertyData).parent.type !== NodeTypename.NIAM_ENUM
                        && (
                        <ConfirmButton
                            data-cy="rpPropertyPage_btnSave"
                            disabled={isOpen && !itemSelected.hasSaved && connectedEnum? false : true}
                            onClick={(e) => {
                                handleOnBlurEvent(e);
                            }}
                        >
                            Save
                        </ConfirmButton>
                        )
                    }
                </ButtonListFlex>

                {(itemSelected.data as RPPropertyData).relatedTo && (
                    <>
                        <Divider />

                        <RightPanelDeleteIconStyled
                            onClick={() => {
                                setIsOpen(false);

                                deleteField({
                                    variables: {
                                        fieldID: data.niamField._id
                                    }
                                }).then(({ errors }: any) => {
                                    if (errors) {
                                        for (const e of errors) {
                                            toastError(t(`validations:${e.message}`));
                                        }
                                        return;
                                    }

                                    onDelete({
                                        id: data.niamField._id,
                                        type: EDeleteType.RELATIONSHIP
                                    });

                                    deleteD3Field({
                                        d3Data,
                                        data: dataContext,
                                        setData,
                                        fieldId: data.niamField._id
                                    });
                                });
                            }}
                        />
                    </>
                )}
            </Form>
        </>
    );
}

export default RPProperty;
