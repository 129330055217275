/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { base64_encode } from "@3edges/utils/dist/utils";
import axios from "axios";
import { delStorage, getStorage, setStorage } from "cache";
import { useCookie } from "contexts/cookieContext";
import { REACT_ENV } from "environmentVariables";
import Cookies from "js-cookie";
import JWT from 'jsonwebtoken';
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { generateNonceAndStateOIDC, getCookieDomain } from "../../utils";
import { authToken, checkToken } from "../../utilsFn";

function Code (): React.ReactElement {
    const search = useLocation().search;
    const { setCookie } = useCookie();

    useEffect(() => {
        exchangeCode()
    }, []);

    const exchangeCode = async () => {
        const code = new URLSearchParams(search).get('code');
        const state = new URLSearchParams(search).get('state');
        const code_verifier = getStorage("code_verifier")
        const redirectURL = getStorage("redirectURL")
        const stateOrigin = getStorage("state")

        if(state !== stateOrigin) {
            window.location.replace(window.location.origin);
            return;
        }

        const grant_type = 'authorization_code';
        const client_id = REACT_ENV.REACT_APP_OIDC_CLIENT_ID;
        const redirect_uri = `${window.location.origin}/code`

        const { data } = await requestOIDCWithCode(grant_type, code, client_id, redirect_uri, code_verifier)

        if (!data || data?.error)
        {
            window.location.replace(window.location.origin);
            return;
        }

        const { id_token, access_token, refresh_token } = data
        const { payload } = JWT.decode(id_token, { complete: true });
        const { nonce } = await generateNonceAndStateOIDC()

        setStorage("user", {
            ...payload
        });

        const now = Number.parseInt((Date.now() / 1000).toString());
        const minutes = Math.round(payload.exp - now) / 60;

        const nowNonce = new Date();
        nowNonce.setTime(nowNonce.getTime() + 5 * 60 * 1000); // 5 minutes

        Cookies.set(REACT_ENV.REACT_APP_NONCE_COOKIE_NAME, nonce, {
            path: "/",
            expires: now,
            domain: getCookieDomain(),
            secure: window.location.protocol === 'https:' ? true : false,
            sameSite: 'strict',
        });

        await checkToken(id_token)
        setCookie(REACT_ENV.REACT_APP_ID_TOKEN_COOKIE_NAME, base64_encode(id_token), minutes);
        setCookie(REACT_ENV.REACT_APP_ACCESS_TOKEN_COOKIE_NAME, access_token, minutes);
        setStorage(REACT_ENV.REACT_APP_REFRESH_TOKEN_LOCAL_STORAGE_NAME ? REACT_ENV.REACT_APP_REFRESH_TOKEN_LOCAL_STORAGE_NAME : "default_nid_r", refresh_token);

        if(redirectURL) {
            delStorage("redirectURL");
            const redirect = redirectURL as string
            window.location.href = redirect.includes("http") ? redirect : `${window.location.protocol}//${redirect}`;
        } else {
            window.location.replace(window.location.origin);
        }
    }

    const requestOIDCWithCode = async (grant_type, code, client_id, redirect_uri, code_verifier) => {
        try {
            const params = new URLSearchParams();
            params.append("grant_type", grant_type);
            params.append("code", code);
            params.append("client_id", client_id);
            params.append("redirect_uri", redirect_uri);
            params.append("code_verifier", code_verifier);

            return await axios.post(`${REACT_ENV.REACT_APP_OIDC_URL}${REACT_ENV.REACT_APP_OIDC_TOKEN_ENDPOINT}`, params, {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    Authorization: `Basic ${authToken}`
                }
            });
        } catch (error) {
            return error;
        }
    }

    return null;
}

export default Code;
